.App {
  background-color: #1e4d91;
}

@tailwind base;
@tailwind components;
@tailwind utilities;



